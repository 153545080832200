import React from 'react';
// import Header from './Header';
import styles from './ReadMore.module.css';
// import { Link } from 'react-router-dom';
import JoinLink from './JoinLink';
import { useEffect } from 'react';

const ReadMore = () => {
  useEffect(() => {
    const element = document.getElementById('main');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <div id='main'>
      <div className={styles.container}>
        <div className={styles.content}>
        <h2  className={styles.headingOffer}
        
        >Check out our</h2>
        <h1 className={styles.headingBirthday}>
        Featured projects
        </h1>
          <img className={styles.line} src="home-line.png" alt="Decorative Line" />
          <div className={styles.gametypes}>
          <div className={styles.gametype}>
          <img  src='/game1.webp' alt='Game1'></img>
          <h2>KangaSafari</h2>
          <p>KangaSafari is a mobile simulation game that promotes the beauty and diversity of Australian wildlife.</p>
          <p>You play as an Australian park ranger in training, tasked with exploring the great outdoors to rescue injured native animals and discover local flora. Rescue, rehabilitate, and release animals to become a better ranger and gain access to national parks across Australia, unlocking a whole new world of native flora and fauna.</p>
          </div>
          <div className={styles.gametype}>
          <img  src='/game2.webp' alt='Game2'></img>
          <h2>CrabVentures</h2>
          <p>CrabVentures is an atmospheric underwater strategy game where you nurture a quirky family of Crablings and breathe life into your underwater domain.</p>
          <p>As the guardian of the abyss, you must defend the egg of the next crab god, cultivate enough food to sustain it, and migrate to deeper waters where it will hatch and become the new ocean caretaker. Your colony of Crablings will build mighty coral reefs, protect against threats, and collect food for the journey ahead.</p>
          </div>
          <div className={styles.gametype}>
          <img  src='/game3.webp' alt='Game3'></img>
          <h2>SecretSaga</h2>
          <p>SecretSaga explores a fantasy world with familiar problems. In the world of Kaya, its population faces an oppressive darkness causing rising food insecurity, and as a result, raging conflict.</p>
          <p>SecretSaga is a training game created by the United Nations World Food Programme (WFP) to help educate its employees and cooperating partners to better apply a humanitarian protection lens to their work and to improve accountability to affected populations.</p>
          </div>
        </div>
          </div>
          
          </div>
          <div className={styles.linkcontainer}>
          <JoinLink/>
          </div>
    </div>
  );
};

export default ReadMore;
