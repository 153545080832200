import React from 'react';
// import { Link } from 'react-router-dom';
import styles from './LoginLink.module.css';

const LoginLink = () => {
  return (
    <a href="https://OpenDevGames.com/getintouch.html" target="blank" className={styles.loginLink}>
      GET STARTED
    </a>
  );
};

export default LoginLink;
