import React from 'react';
// import Header from './Header';
import styles from './Home.module.css';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2  className={styles.headingOffer}
        
        >Check out our</h2>
        <h1 className={styles.headingBirthday}>
        Featured projects
        </h1>
        <img className={styles.line} src="home-line.png" alt='line'></img>
        <div className={styles.gametypes}>
          <div className={styles.gametype}>
          <img  src='/game1.webp' alt='Game1'></img>
          <h2>KangaSafari</h2>
          </div>
          <div className={styles.gametype}>
          <img  src='/game2.webp' alt='Game2'></img>
          <h2>CrabVentures</h2>
          </div>
          <div className={styles.gametype}>
          <img  src='/game3.webp' alt='Game3'></img>
          <h2>SecretSaga</h2>
          </div>
        </div>
        
         <Link to="/readmore" className={styles.linkReadMore}>
      Read more
      </Link>
      </div>
     
     
    </div>
  );
};

export default Home;
