import React from 'react';
import styles from './MobileLink.module.css';

const MobileLink = () => {
  return (
    <a href="https://OpenDevGames.com/getintouch.html" target='blank' className={styles.mobileLink}>
      <img src='/mobile-menu.svg' alt='menu'></img>
    </a>
  );
};

export default MobileLink;
