import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './Layout';
import Home from './Home';
import ReadMore from './ReadMore';
// import Getintouch from './Getintouch';
import Terms from './Terms';

import './App.css'

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="readmore" element={<ReadMore />} />
        </Route>
        <Route path="terms" element={<Terms />} />
        {/* <Route path="getintouch" element={<Getintouch />} /> */}
      </Routes>
    </Router>
  );
};

export default App;

