import { Outlet } from "react-router-dom";
import Header from './Header'
import Footer from "./Footer";
import CookieBar from './CookieBar';

const Layout = () => {
  return (
    <>
      <Header/>
      <Outlet />
      <Footer/>
      <CookieBar/>
    </>
  )
};

export default Layout;