import React from 'react';
import styles from './Terms.module.css'; 
import Footer from './Footer';
// import Header from './Header'

const Terms = () => {
    return (
        <>
 {/* <Header /> */}
    <div className={styles.termsInfo}>
    <h1>Terms and Conditions</h1>
    
    <p>
        Greetings from OpenDevGames.com! By accessing or utilizing our platform at <strong>OpenDevGames.com</strong>, you acknowledge and agree to comply with the following terms. Should you disagree, we kindly ask you to discontinue using our website.
    </p>

    <h2>1. Ownership of Intellectual Property</h2>
    <p>
        The entire content displayed on our platform, including but not limited to text, graphics, logos, icons, images, and videos, belongs to OpenDevGames.com and is safeguarded under copyright and intellectual property laws. Unauthorized use, reproduction, or distribution of this content is strictly forbidden.
    </p>

    <h2>2. Permissible Use</h2>
    <p>
        Our site is designed for your personal use to explore the offerings and services provided by our team. Modifying, distributing, or copying any materials without our explicit written permission is prohibited. Engaging in activities that could compromise the security or functionality of our site, such as introducing harmful software, is not allowed.
    </p>

    <h2>3. Accuracy of Information</h2>
    <p>
        We endeavor to provide accurate and up-to-date information regarding our products, pricing, and availability. However, we cannot guarantee that all details are free of errors. We reserve the right to make corrections or adjustments to any inaccuracies without prior notification.
    </p>

    <h2>4. Pricing and Payments</h2>
    <p>
        The prices for our products are subject to change and are listed in the local currency. We offer secure payment options, with accepted payment methods clearly specified during the checkout process.
    </p>

    <h2>5. Orders and Cancellations</h2>
    <p>
        When you place an order with OpenDevGames.com, you are making an offer to purchase our products. We retain the right to accept or decline your order. In the event of cancellations due to reasons such as stock shortages or payment issues, we will notify the affected customers promptly and provide appropriate compensation.
    </p>

    <h2>6. Delivery and Returns</h2>
    <p>
        We provide delivery services for the products available on our site. Any cancellations or modifications to your order must be made in line with our delivery policy. We reserve the right to cancel or reschedule deliveries if necessary and will inform customers of any changes without delay.
    </p>

    <h2>7. External Links Disclaimer</h2>
    <p>
        Our platform may include links to third-party websites that are not under our control. We are not responsible for the content or privacy practices of these external sites, and we encourage users to exercise caution when visiting them.
    </p>

    <h2>8. Limitation of Liability</h2>
    <p>
        OpenDevGames.com and its affiliates will not be liable for any direct, indirect, incidental, special, or consequential damages that may arise from your use of our site or reliance on the information provided herein. Our liability is limited to the maximum extent permitted by law.
    </p>

    <h2>9. Acceptance of These Terms</h2>
    <p>
        These terms are subject to change without prior notice. Continued use of our platform following any updates signifies your acceptance of the revised terms. If you have any inquiries regarding these terms, please contact us at <a href="mailto:support@OpenDevGames.com">support@OpenDevGames.com</a>.
    </p>
</div>

            <Footer />
            </>
  );
};

export default Terms;