import React from 'react';
// import { Link } from 'react-router-dom';
import styles from './JoinLink.module.css';

const JoinLink = () => {
  return (
    <a href="https://OpenDevGames.com/getintouch.html" target="blank" className={styles.joinLink}>
      ORDER NOW
    </a>
  );
};

export default JoinLink;
