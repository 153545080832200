import React from 'react';
import styles from './Footer.module.css';
import LoginLink from './LoginLink';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className={styles.footer}>
          <div className={styles.footerContent}>
      <div className={styles.footerLeft}>
        {/* <img className={styles.footerImg} src="logo.png" alt='logo'></img> */}
      </div>
      <div className={styles.footerRight}>
      <h1>Develop Your Own Gaming Story</h1>
      <p className={styles.text}>Join our exclusive circle and stay informed about our game development services.</p>
      <LoginLink />
        </div>
        <div className={styles.contactDetails}>
        <p>
          We’d love to hear from you! Whether you have questions about our game development services, feedback, or just want to say hello, feel free to reach out to us.
        </p>
        <p>
          <strong>Email:</strong> <a href="mailto:support@OpenDevGames.com">support@OpenDevGames.com</a>
        </p>
        <p>
          <strong>Office Address:</strong><br />
          OpenDevGames.com<br />
          123 Game Developer Lane<br />
          Melbourne, VIC 3000<br />
          Australia
          </p>
           <Link to="/terms">Terms and Conditions</Link>
      </div>
      </div>
    </div>
  );
}

export default Footer;
